<template>
  <v-row dense>
    <v-col md="5">
      <DebouncedTextField
        :input="text => handleInput('ADD_VIEW_SEARCH_STUDENT', text)"
        label="Search Assignment"
        :value="viewStudents.searchAssignment"
      />
    </v-col>
    <v-col md="5">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        item-text="name"
        item-value="uuid"
        :items="courseChoices"
        label="Course"
        solo
        :value="viewStudents.selectedCourse"
        @input="
          courseId => handleInput('ADD_VIEW_STUDENT_SELECTED_COURSE', courseId)
        "
      />
    </v-col>
    <v-col md="2">
      <v-select
        class="header-fields"
        clearable
        :disabled="listLoading"
        flat
        hide-details
        :items="statusList"
        label="Status"
        solo
        :value="viewStudents.selectedStatus"
        @input="status => handleInput('ADD_VIEW_STUDENT_STATUS', status)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import DebouncedTextField from "@/components/shared/DebouncedTextField.vue"

export default {
  name: "ViewStudentSearch",

  components: { DebouncedTextField },

  props: {
    reset: {
      type: Function,
      required: true,
    },
    getStudentsData: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    studentId: null,
  }),

  computed: {
    ...mapGetters([
      "courseChoices",
      "statusList",
      "viewStudents",
      "listLoading",
    ]),
  },

  beforeMount: function() {
    this.studentId = this.$route.params.studentId
  },

  methods: {
    ...mapActions(["getViewStudents"]),

    //  handleInput
    handleInput(request, value) {
      this.reset()
      this.$store.commit(request, value ? value : "")
      this.getViewStudents(this.studentId)
      this.getStudentsData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
