<template>
  <div>
    <v-row align="end" justify="space-between" no-gutters>
      <v-col cols="auto">
        <GoBack />
        <p class="header-text">
          {{ studentData.user.full_name }}
        </p>
        <p class="lightCarbon--text font-weight-regular caption">
          {{ studentData.user.email }} ·
          {{ studentData.batch_name }}
        </p>
      </v-col>

      <v-col cols="auto">
        <v-btn
          class="px-14 button-start-checking"
          color="primary"
          depressed
          min-height="55"
          min-width="60"
          :to="{
            name: 'StartChecking',
            query: {
              studentId,
            },
          }"
        >
          Start Checking
        </v-btn>
        <p class="mt-1 text-center font-weight-medium text--secondary caption">
          Start checking assignments of this student
        </p>
      </v-col>
    </v-row>

    <v-divider class="mt-2 mb-4" />

    <v-row class="my-6 mb-16" dense>
      <v-col
        v-for="(item, index) in cards"
        :key="index"
        cols="12"
        lg="3"
        md="6"
      >
        <AnalyticsCard
          :body="item.body"
          :color="item.color"
          :title="item.title"
        />
      </v-col>
    </v-row>

    <div class="my-6">
      <ViewStudentSearch
        :get-students-data="getStudentsData"
        :reset="resetPageNo"
      />
    </div>

    <v-data-table
      :custom-sort="() => viewStudents.data.data"
      :headers="headers"
      hide-default-footer
      :items="viewStudents.data.data"
      :loading="listLoading"
      multi-sort
      :sort-by="viewStudents.sort.fields"
      :sort-desc="viewStudents.sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.assignment__title`]="{ item }">
        {{
          item.assignment.title
            | formatAssignmentTitle(item.assignment.short_title)
        }}
      </template>

      <template v-slot:[`item.submitted_at`]="{ item }">
        {{ item.submitted_at ? moment(item.submitted_at).format("ll") : "-" }}
      </template>

      <template v-slot:[`item.courses`]="{ item }">
        {{ item.courses | formatCourses }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span v-if="item.review?.result === 'passed'" class="green--text">
          Passed
        </span>
        <span v-else-if="item.review?.result === 'failed'" class="red--text">
          Failed
        </span>
        <span v-else-if="!item.status" class="red--text">
          Not Reviewed
        </span>
        <span
          v-else-if="
            item.status === 'not_reviewed' || item.status === 'under_review'
          "
          class="orange--text d-flex align-center"
        >
          Pending
        </span>
        <span v-else-if="item.status === 'accepted'" class="green--text">
          Accepted
        </span>
        <span v-else-if="item.status === 'rejected'" class="red--text">
          Rejected
        </span>
        <span v-else-if="item.status === 'plagiarised'" class="red--text">
          Plagiarised
        </span>
      </template>

      <template v-slot:[`item.review`]="{ item }">
        {{ item.review && item.review.professor ? item.review.professor : "-" }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn color="primary" small text :to="getLink(item.uuid)">
          VIEW SUBMISSION
        </v-btn>
      </template>
      <template v-slot:footer>
        <Paginator
          :page="viewStudents.page"
          :total="viewStudents.data.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import ViewStudentSearch from "@/components/dashboard/assignments/ViewStudentSearch.vue"
import Paginator from "@/components/shared/Paginator.vue"
import AnalyticsCard from "@/components/shared/AnalyticsCard.vue"
import GoBack from "@/components/shared/GoBack.vue"

import urls from "@/utils/urls"
import { getValueOrNull } from "@/utils/helpers"
import filters from "@/utils/mixins/filters"

export default {
  name: "ViewStudent",

  components: { ViewStudentSearch, Paginator, GoBack, AnalyticsCard },

  mixins: [filters],

  data: () => ({
    studentId: null,
    studentData: {
      total_assignments: 0,
      accepted_submissions: 0,
      pending_reviews: 0,
      total_submissions: 0,
      batch_name: "Batch",
      user: {
        email: "",
        full_name: "",
      },
    },
    headers: [
      {
        text: "Assignment",
        value: "assignment__title",
      },
      { text: "Courses", value: "courses", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Reviewer", value: "review", sortable: false },
      {
        text: "Submissions",
        value: "order",
        sortable: false,
      },
      {
        text: "Date",
        value: "submitted_at",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        sortable: false,
        align: "end",
      },
    ],
  }),

  computed: {
    ...mapGetters(["viewStudents", "listLoading"]),

    totalEntries() {
      const { data, page } = this.viewStudents

      return `${page * 10 > data.total ? data.total : page * 10} of ${
        data.total
      } Entries`
    },

    cards() {
      const {
        total_assignments,
        total_submissions,
        accepted_submissions,
        pending_reviews,
      } = this.studentData
      return [
        {
          title: "Total Assignments",
          color: "purple",
          body: total_assignments,
        },
        {
          title: "Total Submissions",
          color: "yellow",
          body: total_submissions,
        },
        {
          title: "Accepted Submissions",
          color: "green",
          body: accepted_submissions,
        },
        {
          title: "Pending Reviews",
          color: "red",
          body: pending_reviews,
        },
      ]
    },
  },

  beforeMount: function() {
    this.studentId = this.$route.params.studentId
  },

  mounted: function() {
    this.getStudentsData()
  },

  methods: {
    ...mapActions(["getViewStudents"]),

    async getStudentsData() {
      try {
        const res = await this.$http.get(
          urls.students.overview(this.studentId),
          {
            params: {
              course: getValueOrNull(this.viewStudents.selectedCourse),
              status: getValueOrNull(this.viewStudents.selectedStatus),
            },
          }
        )
        this.studentData = res.data.data
      } catch (error) {
        console.error(error)
      }
    },
    resetPageNo() {
      this.$store.commit("SET_VIEW_STUDENT_PAGE", { page: 1 })
    },

    handleSort({ sortBy, sortDesc }) {
      this.resetPageNo()
      this.$store.commit("SET_VIEW_STUDENT_SORTABLE_FIELDS", {
        fields: sortBy,
        desc: sortDesc,
      })
      this.getViewStudents(this.studentId)
    },

    handlePagination(newPage) {
      this.$store.commit("SET_VIEW_STUDENT_PAGE", { page: newPage })
      this.getViewStudents(this.studentId)
    },

    getLink(submissionId) {
      return submissionId
        ? {
            name: "ViewSubmission",
            params: {
              submissionId,
            },
          }
        : undefined
    },
  },
}
</script>

<style lang="scss" scoped>
.re-submission-icon {
  position: relative;
  background: #3b82f6;
  border-radius: 4px;
  color: white;
  margin-left: 8px;
  padding: 4px;
}
</style>
